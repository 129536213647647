import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "CSV to JSON",
    link: "/csv-to-json-converter/",
  },
]
const seeAlsoArray = [
  "/json-to-csv-conversion",
  "/csv-to-sql-conversion",
  "/csv-to-tabel",
  "/text-to-csv-conversion",
  "/json-to-xml",
]

function ConvertCSVtoJSON(props) {
  let [csvTexarea, setcsvTexarea] = useState(
    '"Id","UserName"\n"1","Roy"\n"2","Mike"\n"3","Jamal"'
  )
  let [jsonTextarea, setjsonTextarea] = useState("")

  function csvTexareaC(e) {
    setcsvTexarea(e.target.value)
  }

  function convert() {
    let data = csvTexarea
    import("csvjson").then(e => {
      let csvjson = e.default

      let json = csvjson.toObject(data, {
        delimiter: ",",
        quote: '"',
      })
      setjsonTextarea(JSON.stringify(json, null, 2))
    })
  }

  function handleFileSelect(evt) {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      var f = evt.target.files[0]
      var reader = new FileReader()
      reader.onload = (function () {
        return function (e) {
          var csvInput = e.target.result
          setcsvTexarea(csvInput)
        }
      })(f)
      reader.readAsBinaryString(f)
    }
  }

  function downloadObjectAsJson() {
    var dataStr =
      "data:text/json;charset=utf-8," + encodeURIComponent(jsonTextarea)
    var downloadAnchorNode = document.createElement("a")
    downloadAnchorNode.setAttribute("href", dataStr)
    downloadAnchorNode.setAttribute("download", "json-file.json")
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="CSV to JSON, Csv To Json Conversion"
        description="CSV to JSON, CSV To Json Conversion,  Simple Converter, CSV stands for Comma Separated Values. Often used as an interchange data format to represent table records, one per line. CSV is plain text, step by step."
        keywords={[
          "CSV to JSON Conversion, CSV to JSON, Csv To Json, Csv To Json Converter,Csv To Json translator,Csv To Json, Csv To Json translator,convert Csv To Json, Csv To Json convertion, Csv To Json, Csv To Json converter and download.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Convert CSV to JSON</Title>
        <SubTitle>CSV to JSON</SubTitle>
        <label>
          <input
            type="file"
            accept=".txt, .csv, .json"
            name="files"
            onChange={handleFileSelect}
          />
          <span>Choose a file… </span>
        </label>
        <br />

        <Span>Or Paste Your CSV Here:</Span>
        <Textarea
          className="mb-2"
          value={csvTexarea}
          onChange={csvTexareaC}
        ></Textarea>
        <Button
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          className="mb-2"
          onClick={convert}
        >
          Convert To JSON
        </Button>
        <Textarea className="mb-2" value={jsonTextarea} readOnly></Textarea>
        <Button
          borderColor="#1da1f2"
          color="white"
          backgroundColor="#1da1f2"
          hoverColor="#1da1f2"
          hoverBorderColor="#1da1f2"
          className="mb-2"
          onClick={downloadObjectAsJson}
        >
          Download JSON
        </Button>
        <br />
        <br />
        <h3 className="title is-size-5 mycolor">CSV</h3>
        <p>
          CSV file is a plain text of a data list, it is used for the database.
          A Comma separated values CSV file.
          <br />
          CSV file also called Character Separated Values or Comma Delimited
          files. They mostly use the comma character to separate data.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ConvertCSVtoJSON
